var Fastclick = require('fastclick'),
  mediator = require('lib/grow/mediator.js'),
  u = require('lib/grow/util.js');

u.init();

$(document).ready(function() {
  Fastclick(document.body); //remove tap delay for mobile devices

  // Closes the Responsive Menu on Menu Item Click
  $('.navbar-collapse ul li a').click(function() {
      $('.navbar-toggle:visible').click();
  });

  // Fit Text Plugin for Main Header
  $('h1').fitText(
      1.2, {
          minFontSize: '35px',
          maxFontSize: '65px'
      }
  );
});
